import styled from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  background-color: ${props => props.primary ? '#2563eb' : 'transparent'};
  color: ${props => props.primary ? '#ffffff' : '#2563eb'};
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  border-radius: 12px;
  border: 2px solid ${props => props.primary ? '#2563eb' : '#2563eb'};
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.primary ? '#1d4ed8' : '#2563eb'};
    color: #ffffff;
    transform: translateY(-2px);
  }
`;
