import React from 'react';
import Footer from '../components/Footer';
import '../App.css';
import { Button } from '../styles/SharedStyles';

const ProductsPage = () => {
  const products = [
    { 
      name: "Ordify App", 
      description: "Answer engine to your questions.", 
      icon: "/Ordify_white.svg", 
      link: "https://agent.ordify.ai",
      features: []
    },
    { 
      name: "Movewell", 
      description: "Design to combat sedentary lifestyle. It integrates with your lifestyle and recommend times and activities to keep you moving.", 
      icon: "/movewell.png", 
      link: "https://movewell.rogerlam.co",
      features: []
    },
  ];

  const renderProductCards = (products) => (
    products.map((product, index) => (
      <div className="product-card" key={index}>
        <div className="product-content">
          <div className="product-icon">
            <img className="product-image" src={product.icon} alt={product.name} />
          </div>
          <h2 className="product-name">{product.name}</h2>
          <p className="product-description">{product.description}</p>
          <Button as="a" href={product.link} target="_blank" rel="noopener noreferrer" primary>
            {product.name === "Ordify App" ? "Explore" : "Preview"}
          </Button>
        </div>
      </div>
    ))
  );

  return (
    <div className="page-wrapper">
      <div className="products-page-wrapper">
        <div className="container">
          <h1 className="page-title">Our AI Solutions</h1>
          <div className="product-grid">
            {renderProductCards(products)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsPage;
