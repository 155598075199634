import React from 'react';
import styled from 'styled-components';
import HeroSection from '../components/HeroSection';
import CompanyOverview from '../components/CompanyOverview';
//import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <HomePageWrapper>
      <HeroSection />
      <CompanyOverview />
      {/* <CallToAction /> */}
      <Footer />
    </HomePageWrapper>
  );
};

const HomePageWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;

export default HomePage;
